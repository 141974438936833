import React from 'react';
import Card from './components/Card';
//import BackgroundMusic from './components/BackgroundMusic';
import videoBg from './backgrounds/video4.mp4';
import './App.css';

function App() {
  return (
    <div className="App">
      <video autoPlay loop>
         <source src={videoBg} type='video/mp4' />
            Your browser does not support this video.
         </video>

      <Card />
    </div>
  );
}

export default App;