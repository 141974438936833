import React, { useState } from 'react';
import './Card.css';
import { faDiscord, faInstagram, faPaypal, faSpotify } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import pfp from '../../src/pfps/pfp5.jpg';
import ReactTypingEffect from 'react-typing-effect';


const ReactTypingEffectDemo = ({ text }) => {
    return (
      <ReactTypingEffect
        text={text}
        speed='70'
        typingDelay='0'
        eraseSpeed='70'
        eraseDelay='1000'
        cursorRenderer={cursor => <h1>{cursor}</h1>}
        displayTextRenderer={(text, i) => {
          return (
            <h3>
              {text.split('').map((char, i) => {
                const key = `${i}`;
                return (
                  <span
                    key={key}
                  >{char}</span>
                );
              })}
            </h3>
          );
        }}        
      />
    );
  };

function Card() {
  const [name, setName] = useState('Xur');
  const [about, setAbout] = useState('404');

  return ( 
    <div className='Card'>
      <div className='upper-container'>
        <div className='image-container'>
          <img src={pfp} alt=" " height="100px" width="100px" />
        </div>
      </div>
      <div className='lower-container'>
        <h3>{name}</h3>
        <ReactTypingEffectDemo text={[" "+about, ' undefined']} /> 
        <br />
        <a href='https://discord.gg/copy'><FontAwesomeIcon icon={faDiscord} size="xl" /></a>
        <a href='https://instagram.com/walidxee'><FontAwesomeIcon icon={faInstagram} size="2xl" /></a>
        <a href='https://paypal.me/walidXx'><FontAwesomeIcon icon={faPaypal} size="2xl" /></a>
        <a href='https://open.spotify.com/user/31nxreheo72a2rvxjgkeehncuhly?si=e12bc7e216854cc4'><FontAwesomeIcon icon={faSpotify} size="2xl" /></a>
      </div>
    </div>
  );
}

export default Card;
